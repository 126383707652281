<template>
  <div class="wrap" v-loading="loading">
    <!-- 顶部按钮 -->
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="submitForm(true)">保存
      </el-button>
      <el-button type="primary" size="mini" v-print="'#printMe'">
        测试打印
      </el-button>
      <el-button @click="getQuit" size="mini">退出</el-button>
    </div>
    <!-- 基本设置 -->
    <cardTitleCom cardTitle="基本设置">
      <template slot="cardContent">
        <div class="padd15 basicSettings">
          <el-form ref="form" :model="labelTemplateForm" :rules="rules">
            <el-form-item label="模板名称" label-width="80px" prop="lableTemplateName">
              <el-input v-model="labelTemplateForm.lableTemplateName" class="inputWidth"></el-input>
            </el-form-item>
            <div class="x-f">
              <el-form-item label="纸张尺寸" label-width="80px" prop="width">
                <div class="x-f">
                  <span class="fontS14 marR10">宽</span>
                  <el-input type="number" v-model="labelTemplateForm.width" class="marR10"></el-input>
                  <span class="fontS14 marR10">mm</span>
                </div>
              </el-form-item>
              <el-form-item prop="height">
                <div class="x-f">
                  <span class="fontS14 marR10">高</span>
                  <el-input type="number" v-model="labelTemplateForm.height" class="marR10"></el-input>
                  <span class="fontS14 marR10">mm</span>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="背景图片" label-width="80px">
              <accessory-upload listType="picture-card" :limit="1" title="" @getFileItems="getFileItemsData"
                @delFileItems="delFileItems" />
              <div class="y-f-j">
                <span class="fontS14">
                  · 背景图不会被打印，仅用于辅助调整打印项位置。
                </span>
                <span class="fontS14">
                  · 建议图片尺寸比例与纸张尺寸比例一致，单个图片不超过2M。
                </span>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <!-- 模板设置 -->
    <cardTitleCom cardTitle="模板设置">
      <template slot="cardContent">
        <div class="padd15 x-x">
          <!-- 背景图片 -->
          <div style="overflow-x: auto; white-space: nowrap">
            <div ref="parent" id="printMe" class="defaultBgImg print-content" :style="{
                'background-image': `url(${labelTemplateForm.backgroundUrl})`,
                height: labelTemplateForm.height + 'mm',
                width: labelTemplateForm.width + 'mm'
              }">
              <vue-draggable-resizable v-for="(item, index) in labelTemplateForm.lableTemplateContent
                  .labelList" :w="'auto'" :h="'auto'" :x="item.x" :y="item.y" :resizable="false" @dragging="onDrag"
                @dragstop="handleDragStop" :key="index" :config="{ snap: false }" :parent="true"
                ref="draggableResizable">
                <div class="mouseInStyle" style="border: 1px solid transparent"
                  :class="{ activeBorder: index === activeIndex }" @click="handleItemClick(item, index)">
                  <span v-show="
                      item.tagType != 'goodsBarcode' &&
                      item.tagType != 'customText' &&
                      item.tagType != 'qrCode' &&
                      item.tagType != 'nutrient'
                    " :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">{{ item.printContent }}</span>
                  <div class="y-c" v-show="item.tagType == 'nutrient'">
                    <span :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">{{ item.printContent }}</span>
                    <div class="nutrient-table">
                      <table :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">
                        <thead>
                          <tr>
                            <th>{{item.labelName1}}</th>
                            <th>{{item.labelName2}}</th>
                            <th>{{item.labelName3}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(nutrient, index) in nutrients" :key="index">
                            <td>{{ nutrient.nutrientName }}</td>
                            <td>{{ nutrient.nutrientQty }}</td>
                            <td>{{ nutrient.nutrientUnit }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- 条形码 -->
                  <div v-show="item.tagType == 'goodsBarcode'">
                    <JsBarcode value="6901028071069" :displayValue="item.barcodeType != 1" :width="item.barcodeWidth"
                      :height="item.barcodeHeight" />
                  </div>
                  <!-- 自定义文字 -->
                  <span v-show="item.tagType == 'customText'" :style="{
                      fontSize: item.fontSize + 'px',
                      fontWeight: item.bold ? 'bold' : 'normal',
                      textDecoration: item.underline ? 'underline' : 'none'
                    }">
                    {{ item.customText }}
                  </span>
                  <!-- 二维码 -->
                  <el-image v-show="item.tagType == 'qrCode'" :style="{
                      width: item.imageWidth + 'mm',
                      height: item.imageHeight + 'mm'
                    }" :src="
                      item.qrCodeUrl
                        ? item.qrCodeUrl
                        : 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png'
                    " />
                  <i class="el-icon-error activeClear" v-if="index === activeIndex" @click="
                      deleteRow(
                        index,
                        labelTemplateForm.lableTemplateContent.labelList
                      )
                    "></i>
                </div>
              </vue-draggable-resizable>
            </div>
            <!-- 组件内容 -->
            <div class="printDesign">
              <!-- 基础组件 -->
              <div class="marB15">
                <p>基础组件</p>
                <div class="x-w">
                  <div v-for="(item, index) in baseComponentList" :key="index">
                    <div class="buttonCom cursorP" @click="clickComButton(item)">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 价格信息 -->
              <div class="marB15">
                <p>价格信息</p>
                <div class="x-w">
                  <div v-for="(item, index) in priceList" :key="index">
                    <div class="buttonCom cursorP" @click="clickComButton(item)">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 条码和编码 -->
              <div class="marB15">
                <p>条码和编码</p>
                <div class="x-w">
                  <div v-for="(item, index) in barcodeEncodingList" :key="index">
                    <div class="buttonCom cursorP" @click="clickComButton(item)">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 其他信息 -->
              <div class="marB15">
                <p>其他信息</p>
                <div class="x-w">
                  <div v-for="(item, index) in otherList" :key="index">
                    <div class="buttonCom cursorP" @click="clickComButton(item, index)">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 组件样式 -->
          <div class="designEditor" v-if="
              labelTemplateForm.lableTemplateContent.labelList.length > 0 &&
              activeIndex > -1
            ">
            <div class="designEditorTitle">
              <span>{{
                labelTemplateForm.lableTemplateContent.labelList[activeIndex]
                  .name
              }}</span>
              <i class="el-icon-delete" @click="
                  deleteRow(
                    activeIndex,
                    labelTemplateForm.lableTemplateContent.labelList
                  )
                "></i>
            </div>
            <div>
              <!-- 具体样式 -->
              <div>
                <!-- 坐标 -->
                <div class="controlGroup x-f">
                  <div class="controlGroupLabel">
                    <span class="asterisk">*</span>
                    <span>坐标：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <span class="fontS14 marR8">横坐标</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].x
                        " controls-position="right" :min="0" :max="9999" />
                      <span class="fontS14 marR8">纵坐标</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].y
                        " controls-position="right" :min="0" :max="9999" />
                    </div>
                  </div>
                </div>
                <!-- 文本内容 自定义文字 -->
                <div class="controlGroup x-x" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'customText'
                  ">
                  <div class="controlGroupLabel">
                    <span class="asterisk">*</span>
                    <span>文本内容：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <el-input type="textarea" :rows="3" maxlength="100" show-word-limit placeholder="请输入内容" v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].customText
                        " />
                    </div>
                  </div>
                </div>
                <!-- 打印内容 -->
                <div class="controlGroup x-x" v-show="shouldShowControlGroup">
                  <div class="controlGroupLabel">
                    <span>打印内容：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <el-radio-group v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].printContentType
                        ">
                        <div class="y-start">
                          <div class="marB10">
                            <el-radio :label="1">打印标题和内容</el-radio>
                            <el-tooltip class="item" effect="dark" content="打印出来的内容包含标题和内容两部分，例如：“商品名称：火龙果"
                              placement="top">
                              <i class="iconfont icon-wenhao annotateGrey"></i>
                            </el-tooltip>
                          </div>

                          <div class="x-f printContent marB10">
                            <span class="fontS14 title">标题显示：</span>
                            <el-input :disabled="
                                labelTemplateForm.lableTemplateContent
                                  .labelList[activeIndex].printContentType == 2
                              " v-model="
                                labelTemplateForm.lableTemplateContent
                                  .labelList[activeIndex].printContent
                              " />
                          </div>
                          <div>
                            <el-radio :label="2">仅打印内容</el-radio>
                            <el-tooltip class="item" effect="dark" content="如“商品名称：火龙果”，仅打印“火龙果”" placement="top">
                              <i class="iconfont icon-wenhao annotateGrey"></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <!-- 营养成分宽度 -->
                <div v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'nutrient' ">
                  <div class="controlGroup x-x">
                    <div class="controlGroupLabel">
                      <span class="asterisk">*</span>
                      <span>标题1：</span>
                    </div>
                    <div class="controls">
                      <div class="wrapper">
                        <!-- <span class="fontS14 marR8">名称</span> -->
                        <el-input size="mini" show-word-limit placeholder="请输入内容" v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelName1
                        " />
                        <!-- <span class="fontS14 marR8">宽度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelWidth1
                        " controls-position="right" :min="0" :max="9999" /> -->
                      </div>
                    </div>
                  </div>
                  <div class="controlGroup x-x">
                    <div class="controlGroupLabel">
                      <span class="asterisk">*</span>
                      <span>标题2：</span>
                    </div>
                    <div class="controls">
                      <div class="wrapper">
                        <!-- <span class="fontS14 marR8">名称</span> -->
                        <el-input size="mini" show-word-limit placeholder="请输入内容" v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelName2
                        " />
                        <!-- <span class="fontS14 marR8">宽度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelWidth2
                        " controls-position="right" :min="0" :max="9999" /> -->
                      </div>
                    </div>
                  </div>
                  <div class="controlGroup x-x">
                    <div class="controlGroupLabel">
                      <span class="asterisk">*</span>
                      <span>标题3：</span>
                    </div>
                    <div class="controls">
                      <div class="wrapper">
                        <!-- <span class="fontS14 marR8">名称</span> -->
                        <el-input size="mini" show-word-limit placeholder="请输入内容" v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelName3
                        " />
                        <!-- <span class="fontS14 marR8">宽度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].labelWidth3
                        " controls-position="right" :min="0" :max="9999" /> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 日期展示样式 -->
                <div class="controlGroup x-x" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'printDate'|| labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'produceDate'
                  ">
                  <div class="controlGroupLabel">
                    <span>展示样式：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <el-radio-group v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].printDateType
                        ">
                        <div class="y-start">
                          <el-radio class="marB10" :label="1">YYYY年MM月DD日，如：2018年07月12日</el-radio>
                          <el-radio class="marB10" :label="2">YYYY/MM/DD，如：2018/07/12
                          </el-radio>
                          <el-radio class="marB10" :label="3">YYYY年MM年DD日
                            HH时mm分ss秒，如：2018年07月12日10时24分56秒
                          </el-radio>
                          <el-radio :label="4">YYYY/MM/DD HH:mm:ss，如：2018/07/12 10:24:56
                          </el-radio>
                        </div>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <!-- 延后几天 -->
                <div class="controlGroup x-f" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'produceDate'
                  ">
                  <div class="controlGroupLabel">
                    <span>延后天数：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <div class="fontSizeSelect">
                        <el-input v-model="labelTemplateForm.lableTemplateContent.labelList[activeIndex].delayDate" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 字体大小 -->
                <div class="controlGroup x-f" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType != 'goodsBarcode' &&
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType != 'qrCode'
                  ">
                  <div class="controlGroupLabel">
                    <span class="asterisk">*</span>
                    <span>字体大小：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <div class="fontSizeSelect">
                        <el-select v-model="
                            labelTemplateForm.lableTemplateContent.labelList[
                              activeIndex
                            ].fontSize
                          " placeholder="字体大小" filterable>
                          <el-option v-for="item in fontSizeList" :key="item" :label="item" :value="item" />
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 文字样式 -->
                <div class="controlGroup x-f" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType != 'goodsBarcode' &&
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType != 'qrCode'
                  ">
                  <div class="controlGroupLabel">
                    <span>文字样式：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <svg-icon icon-class="bold" class="marR10 cursorP" v-show="
                          !labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].bold
                        " @click="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].bold = true
                        " />
                      <svg-icon icon-class="boldSelect" class="marR10 cursorP" v-show="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].bold
                        " @click="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].bold = false
                        " />
                      <svg-icon icon-class="underline" class="cursorP" v-show="
                          !labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].underline
                        " @click="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].underline = true
                        " />
                      <svg-icon icon-class="underlineSelect" class="cursorP" v-show="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].underline
                        " @click="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].underline = false
                        " />
                    </div>
                  </div>
                </div>
                <!-- 条码展示样式 -->
                <div class="controlGroup x-x" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'goodsBarcode'
                  ">
                  <div class="controlGroupLabel">
                    <span>展示样式：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <el-radio-group v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].barcodeType
                        ">
                        <div class="y-start">
                          <el-radio class="marB10" :label="1">展示为条码</el-radio>
                          <el-radio class="marB10" :label="2">展示为条码+数字</el-radio>
                        </div>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
                <!-- 条码大小 -->
                <div class="controlGroup x-f" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'goodsBarcode'
                  ">
                  <div class="controlGroupLabel">
                    <span class="asterisk">*</span>
                    <span>条码大小：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <span class="fontS14 marR8">宽度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].barcodeWidth
                        " controls-position="right" :min="0" :max="9999" />
                      <span class="fontS14 marR8">高度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].barcodeHeight
                        " controls-position="right" :min="0" :max="9999" />
                    </div>
                  </div>
                </div>
                <!-- 二维码 -->
                <div class="controlGroup x-x" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'qrCode'
                  ">
                  <div class="controlGroupLabel">
                    <span>上传图片：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <accessory-upload listType="picture-card" :limit="1" title=""
                        @getFileItems="getFileItemsDataQRCode" @delFileItems="delFileItemsQRCode" />
                    </div>
                  </div>
                </div>
                <!-- 二维码大小 -->
                <div class="controlGroup x-f" v-show="
                    labelTemplateForm.lableTemplateContent.labelList[
                      activeIndex
                    ].tagType == 'qrCode'
                  ">
                  <div class="controlGroupLabel">
                    <span class="asterisk">*</span>
                    <span>图片大小：</span>
                  </div>
                  <div class="controls">
                    <div class="wrapper">
                      <span class="fontS14 marR8">宽度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].imageWidth
                        " controls-position="right" :min="0" :max="9999" />
                      <span class="fontS14 marR8">高度</span>
                      <el-input-number v-model="
                          labelTemplateForm.lableTemplateContent.labelList[
                            activeIndex
                          ].imageHeight
                        " controls-position="right" :min="0" :max="9999" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import { deepCopy } from '@/utils'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue' //上传图片
//拖拽插件
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import {
  labelTemplateAddAPI,
  labelTemplateDetailAPI,
  labelTemplateUpdateAPI
} from '@/api/system/goods/labelTemplate'
import JsBarcode from '@/views/components/jsbarcode'
export default {
  name: 'labelTemplateDetail',
  components: {
    JsBarcode,
    cardTitleCom,
    AccessoryUpload,
    VueDraggableResizable
  },
  data() {
    return {
      nutrients: [
        {
          nutrientName: '蛋白质',
          nutrientQty: '8.8克(g)',
          nutrientUnit: '15%'
        },
        {
          nutrientName: '碳水化合物',
          nutrientQty: '25.8克(g)',
          nutrientUnit: '9%'
        },
        {
          nutrientName: '脂肪',
          nutrientQty: '62.2克(g)',
          nutrientUnit: '104%'
        },
        // 添加更多营养物质...
      ],
      //字体大小
      fontSizeList: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
      //选中组件样式的下标
      activeIndex: -1,
      //基础组件
      baseComponentList: [
        { name: '商品名称', tagType: 'goodsName', printTagType: 'GoodsName' },
        { name: '规格', tagType: 'spec', printTagType: 'Spec' },
        { name: '计价方式', tagType: 'costType', printTagType: 'CostType' },
        { name: '单位', tagType: 'unit', printTagType: 'Unit' },
        { name: '品牌', tagType: 'brand', printTagType: 'Brand' },
        {
          name: '保质期天数',
          tagType: 'qualityDays',
          printTagType: 'QualityDays'
        },
        {
          name: '营养成分',
          tagType: 'nutrient',
          printTagType: 'Nutrient'
        },
        // {
        //   name: '自定义参数',
        //   tagType: 'customParameter',
        //   printTagType: 'CustomParameter'
        // }
      ],
      //价格信息
      priceList: [
        { name: '零售价', tagType: 'retailPrice', printTagType: 'RetailPrice' },
        // { name: '会员价', tagType: 'memberPrice', printTagType: 'MemberPrice' },
        {
          name: '标准价',
          tagType: 'standardPrice',
          printTagType: 'StandardPrice'
        }
      ],
      //条码编码
      barcodeEncodingList: [
        {
          name: '商品条码',
          tagType: 'goodsBarcode',
          printTagType: 'GoodsBarcode'
        },
        { name: '商品编码', tagType: 'goodsNo', printTagType: 'GoodsNo' },
        { name: '商品备注', tagType: 'remark', printTagType: 'Remark' },
        { name: '商品备注2', tagType: 'remark2', printTagType: 'Remark2' },
        { name: '商品重量', tagType: 'weight', printTagType: 'Weight' },
        { name: '储存方式', tagType: 'depositWay', printTagType: 'DepositWay' },
      ],
      //其他信息
      otherList: [
        {
          name: '自定义文字',
          tagType: 'customText',
          printTagType: 'CustomText'
        },
        { name: '图片', tagType: 'qrCode', printTagType: 'QrCode' },
        { name: '打印日期', tagType: 'printDate', printTagType: 'PrintDate' },
        { name: '生产日期', tagType: 'produceDate', printTagType: 'ProduceDate' },
        { name: '操作人', tagType: 'operateName', printTagType: 'OperateName' }
      ],
      //遮罩
      loading: false,
      //旧的表单
      oldForm: {},
      //组件表单
      labelTemplateForm: {
        height: 40,
        width: 60,
        backgroundUrl: '', //背景图片
        lableTemplateContent: {
          labelList: [] //商品名称
        } //自定义内容
      },
      rules: {
        lableTemplateName: [
          {
            required: true,
            message: '模板名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        width: [
          {
            required: true,
            message: '便签宽度不能为空',
            trigger: ['blur', 'change']
          }
        ],
        height: [
          {
            required: true,
            message: '便签高度不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  computed: {
    //样式打印内容
    shouldShowControlGroup() {
      const labelList = this.labelTemplateForm.lableTemplateContent.labelList
      const activeIndex = this.activeIndex
      const tagType = labelList[activeIndex].tagType
      return (
        tagType !== 'goodsBarcode' &&
        tagType !== 'customText' &&
        tagType !== 'qrCode'
      )
    }
  },
  mounted() {
    //MutationObserver  是一个 JavaScript 的 API，它可以用来监听 DOM 变化并做出相应的处理。该 API 可以在一个元素或一个文档中监视所有的 DOM 变化，包括节点的添加、删除、属性的变化等等。
    const observer = new MutationObserver(() => {
      this.$nextTick(() => {
        const parent = this.$refs.parent
        const parentWidth = parent.offsetWidth
        const parentHeight = parent.offsetHeight
        if (
          this.$refs.draggableResizable &&
          this.$refs.draggableResizable.length > 0
        ) {
          this.$refs.draggableResizable.forEach(item => {
            this.$set(item, 'parentWidth', parentWidth)
            this.$set(item, 'parentHeight', parentHeight)
          })
        }
      })
    })
    //传入需要被监视的节点对象和一个选项对象。
    observer.observe(this.$refs.parent, { attributes: true })
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.lableTemplateId) {
          //获取单据详情
          const { data } = await labelTemplateDetailAPI(
            vm.$route.query.lableTemplateId
          )
          const newObj = JSON.parse(data.lableTemplateContent)
          vm.$set(data, 'lableTemplateContent', newObj)
          //单据赋值
          vm.labelTemplateForm = data
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.labelTemplateForm))
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.labelTemplateForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.labelTemplateForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.labelTemplateForm.lableTemplateId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.labelTemplateForm))
    }
    next()
  },
  watch: {
    //侦听标签宽度
    'labelTemplateForm.width': {
      handler(newVal) {
        // 设置 CSS Variables

        document.documentElement.style.setProperty(
          '--page-width',
          `${this.labelTemplateForm.width}mm`
        )
      },
      immediate: true
    },
    //侦听标签高度
    'labelTemplateForm.height': {
      handler(newVal) {
        // 设置 CSS Variables
        document.documentElement.style.setProperty(
          '--page-height',
          `${this.labelTemplateForm.height}mm`
        )
      },
      immediate: true
    }
  },
  methods: {
    //删除组件
    deleteRow(index, rows) {
      console.log('rows', rows)
      console.log('index', index)
      this.activeIndex = -1
      this.labelTemplateForm.lableTemplateContent.labelList.splice(index, 1)
    },
    // 表单重置
    reset() {
      //选中组件样式的下标
      this.activeIndex = -1
      this.labelTemplateForm = {
        height: 40,
        width: 60,
        backgroundUrl: '', //背景图片
        lableTemplateContent: {
          labelList: [] //商品名称
        } //自定义内容
      }
      this.resetForm('form')
    },
    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const newForm = JSON.parse(JSON.stringify(this.labelTemplateForm))
          newForm.lableTemplateContent = JSON.stringify(
            newForm.lableTemplateContent
          )
          if (newForm.lableTemplateId) {
            await labelTemplateUpdateAPI(newForm)
          } else {
            await labelTemplateAddAPI(newForm)
          }
          this.$message.success('保存成功')
          //退出
          this.getQuit()
        }
      })
    },

    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/goods/goods/labelTemplate" });
    },
    //组件点击事件
    handleItemClick(value, index) {
      //组件选中
      this.activeIndex = index
    },
    //组件按钮点击事件
    clickComButton(value) {
      const newLabel = {
        printContent: `${value.name}${value.tagType == 'nutrient' ? '' : ': '}`,
        name: value.name, //组件名称
        x: 0, //默认横坐标
        y: 0, //默认纵坐标
        tagType: value.tagType, //组件类型
        printTagType: value.printTagType, //组件类型  前端使用
        printContentType: 1, //默认打印内容的类型
        fontSize: 12, //默认字体大小
        bold: false, //默认不加粗
        underline: false //默认没下划线
      }
      if (value.tagType == 'goodsBarcode') {
        //条形码
        newLabel.barcodeWidth = 25.4
        newLabel.barcodeHeight = 6
        newLabel.barcodeType = 2
      } else if (value.tagType == 'customText') {
        newLabel.customText = '自定义文本'
      } else if (value.tagType == 'qrCode') {
        //二维码
        newLabel.imageWidth = '16.67'
        newLabel.imageHeight = '16.67'
      } else if (value.tagType == 'printDate' || value.tagType == 'produceDate') {
        newLabel.printDateType = 1
      } else if (value.tagType == 'nutrient') {
        newLabel.labelName1 = '营养物质'
        newLabel.labelName2 = '数量'
        newLabel.labelName3 = '单位'
      }
      this.labelTemplateForm.lableTemplateContent.labelList.push(newLabel)
      //组件选中
      this.activeIndex =
        this.labelTemplateForm.lableTemplateContent.labelList.length - 1
    },
    // 在此处处理组件拖拽结束后的逻辑，event 包含组件的状态信息
    handleDragStop(x, y, width, height) {
      setTimeout(() => {
        //不是li元素关闭弹窗
        if (this.activeIndex > -1) {
          this.labelTemplateForm.lableTemplateContent.labelList[
            this.activeIndex
          ].x = x
          this.labelTemplateForm.lableTemplateContent.labelList[
            this.activeIndex
          ].y = y
        }
      }, 300)
    },

    //每当拖动组件时调用
    onDrag(x, y) {
      // if (this.activeIndex > -1) {
      //   this.labelTemplateForm.lableTemplateContent.labelList[
      //     this.activeIndex
      //   ].x = x
      //   this.labelTemplateForm.lableTemplateContent.labelList[
      //     this.activeIndex
      //   ].y = y
      // }
    },
    //删除阿里图库
    delFileItems() {
      this.$set(this.labelTemplateForm, 'backgroundUrl', '')
    },
    //上传阿里图库
    getFileItemsData(value) {
      this.$set(this.labelTemplateForm, 'backgroundUrl', value.accessUrl)
    },
    //删除阿里图库  组件图片
    delFileItemsQRCode() {
      this.$set(
        this.labelTemplateForm.lableTemplateContent.labelList[this.activeIndex],
        'qrCodeUrl',
        ''
      )
    },
    //上传阿里图库组件图片
    getFileItemsDataQRCode(value) {
      this.$set(
        this.labelTemplateForm.lableTemplateContent.labelList[this.activeIndex],
        'qrCodeUrl',
        value.accessUrl
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //基本设置
  .basicSettings {
    ::v-deep .el-input--medium .el-input__inner {
      height: 30px;
      line-height: 30px;
      width: 160px;
    }
  }
  //组件样式
  .designEditor {
    margin-left: 15px;
    min-width: 420px;
    padding: 15px;
    background: #f8f8f8;
    border: 1px solid #ebedf0;
    text-align: left;
    position: relative;
    align-self: flex-start; /* Override default equal height */
    //标题样式
    .designEditorTitle {
      font-family: PingFangSC-Semibold;
      font-size: 14px;
      color: #333;
      text-align: left;
      padding-bottom: 9px;
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    //组件
    .controlGroup {
      margin-bottom: 20px;
      color: #323233;
      vertical-align: top;
      .controlGroupLabel {
        display: inline-block;
        width: 82px;
        text-align: right;
        vertical-align: middle;
        font-size: 14px;
        color: #323233;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .controls {
        margin-left: 10px;
        display: inline-block;
        word-break: break-all;
        vertical-align: top;
        .wrapper {
          line-height: 30px;
          min-height: 30px;
          display: flex;
          align-items: center;
          //坐标
          ::v-deep .el-input-number--medium {
            margin-right: 8px;
            width: 100px;
            line-height: 35px;
            height: 35px;
          }
          //打印内容输入框
          .printContent {
            //打印标题
            .title {
              display: inline-block;
              width: 100px;
            }
            ::v-deep .el-input--medium .el-input__inner {
              height: 30px;
              line-height: 30px;
              width: 160px;
            }
          }
          //字体大小输入框
          .fontSizeSelect {
            ::v-deep .el-input--medium .el-input__inner {
              height: 30px;
              line-height: 30px;
              width: 160px;
            }
          }
        }
      }
    }
  }
  //组件样式的伪元素
  .designEditor:before {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: #f8f8f8;
    border-width: 7px;
    margin-top: -7px;
  }
  //默认背景图片
  .defaultBgImg {
    // background-size: 441px 253px;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: baseline;
    width: 441px;
    height: 253px;
    border: 1px solid #dedede;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    //选中时背景颜色
    .activeBorder {
      border-color: #27f !important;
    }
    //选中时删除图标
    .activeClear {
      font-size: 16px;
      position: absolute;
      color: #bbb;
      top: -10px;
      left: calc(100% - 5px);
      cursor: pointer;
    }
    //条形码的数字
    .barcodeNo {
      text-align: center;
      height: 10px;
      line-height: 10px;
      margin-top: 5px;
    }
    //条形码
    ::v-deep .el-image {
      overflow: inherit;
    }
    .mouseInStyle:hover {
      cursor: grab;
    }
    .mouseInStyle:active {
      cursor: grabbing;
    }
  }
  //组件内容
  .printDesign {
    margin-top: 15px;
    background: #fff;
    border: 1px solid #ebedf0;
    padding: 15px 0 15px 15px;
    width: 440px;
    box-sizing: border-box;
    p {
      margin: 0;
      padding: 0;
    }
    //按钮
    .buttonCom {
      display: inline-block;
      background: #f7f8fa;
      border: 1px solid #dcdee0;
      font-size: 14px;
      color: #7d7e80;
      text-align: center;
      line-height: 30px;
      width: 95px;
      height: 30px;
      margin-right: 8px;
      margin-top: 8px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // /* 单选框选中后的字体颜色 */
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #303133 !important;
  }
}
@media print {
  @page {
    size: var(--page-width) var(--page-height); /* 动态设置打印纸张大小 */
    margin: 0;
    padding: 0;
  }
  html,
  body {
    width: var(--page-width); /* 页面宽度 */
    height: var(--page-height); /* 页面高度 */
    margin: 0;
    padding: 0;
    // transform: scale(1); /* 页面放大比例 */
    // transform-origin: center;
    transform: none;
    transform-origin: unset;
  }
  /* 设置背景图片可见 */
  .background-image {
    visibility: visible !important;
  }
  /* 设置背景图片为打印图片 */
  .print-content {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    color-adjust: exact;
    // background-image: url('https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-23/796e0eca32fc4c14875a28d2426c401e.png');
    background-size: cover;

    visibility: visible !important;
  }
}
//拖拽属性默认样式
::v-deep .vdr {
  border: none !important;
}
.nutrient-table {
  border: 1px solid #000000; /* 外层边框 */
  table {
    width: 100%;
    border-collapse: collapse; /* 合并相邻的边框 */
  }
  th {
    border-bottom: 1px solid #000000; /* 单元格底部边框 */
  }
  td:first-child,
  th:first-child {
    text-align: left; /* 第一个td和th文本左对齐 */
  }
  td:nth-child(2),
  td:nth-child(3),
  th:nth-child(2),
  th:nth-child(3) {
    text-align: right; /* 第二个和第三个td和th文本右对齐 */
  }
  td:nth-child(2),
  th:nth-child(2) {
    padding: 0 10px;
  }
}
</style>
